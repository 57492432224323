/*Header*/
#root-wrapper #top {
    border:none !important;
}
.header-container {
    background-color:#fff;
}
.header-top-container {
    text-transform: inherit;
}
.header-top-container .module-header-multi{
     margin-left:0;
     margin-right:0;
     width:100%;
     display:unset;
}
.header-top-container .module-header-multi .item-welcome-msg{
         margin-right: 1% !important;
}
.header-top-container .module-header-multi  .item-switch-customer {
    margin-left: 1% !important;
    margin-right: 1% !important;
}
.top-header-container .module-header-multi span, .top-header-container .module-header-multi a{
     height:32px;
     line-height:32px !important;
     font-family:'Roboto-Light';
}
.top-header-container .module-header-multi p{
     margin:0 !important;
     height:32px;
     line-height:32px;
}
.top-header-container .item.item-user-links {
     margin-right:0;
}
.top-header-container .links>li>a, .userSwitch a, .welcome-msg, .userOnHold{
     color:#fff !important;
     font-family:'Roboto-Light';
}
.top-header-container .links>li>a::after {
    display:none;
}
.top-header-container .links>li>a {
    text-indent: 0;
    padding: 0 .5833em;
    font-size: 14px;
}
.top-header-container .item.item-user-links {
     margin-right:0;
}
.desktop-top-header .grid-container {
     display:flex;
}
.desktop-top-header .grid-container > div {
     margin:auto 0;
}
.desktop-top-header .search-col, .desktop-top-header .cart-col, .desktop-top-header .logo-col {
     width:auto;
}
.cat-col-lt, .cat-col-rt {
     flex:1;
}

.desktop-top-header .logo-col img {
     max-width:350px;
}
.desktop-top-header .header-logo {
     text-align:center;
}
/*Menu*/
.desktop-top-header ul#nav {
    display:unset;
}
.desktop-top-header .nav-regular li.level0 {
    padding: 0;
}
.desktop-top-header .nav-regular li.level0>a {
     text-transform:inherit;
}
.desktop-top-header .nav-item.nav-item--home {
     display:none;
}
#nav .nav-item.level0.parent>a .caret {
     border-top-color:#000 !important;
}
.cat-col-rt ul#nav {
     display:flex;
     justify-content:flex-end;
}
.nav-mobile .nav-item.level0>a span:hover, .nav-regular .nav-item.level0:hover>a span:not(.caret) {
    border-bottom:none;
    color:#000;
    text-decoration: underline;
}
#nav .nav-item.level0.parent:hover>a .caret {
    border-top-color: #000;
}
.nav-regular li.level0>a {
    line-height: 60px;
}
.nav-regular .mega>.nav-panel--dropdown {
    margin-top:0;
}
/*cart*/
.cart-col .dropdown-toggle *, .cart-col .dropdown-menu *{
     font-size:14px;
     font-family:'Roboto-Light';
    color: #000;
}
.cart-col .fa-shopping-cart:before {
    display:none;
}
/*Search*/
.desktop-top-header .search-col img {
     margin-top:5px;
}
.desktop-search-column .module-search.search-wrapper {
     width:48%;
     margin:auto;
}
.desktop-search-column .module-search .form-search input::placeholder {
     color:#D1D1D1 !important;
     font-size:16px;
}
.desktop-search-column .module-search .form-search input.input-text {
     border:none;
     border-bottom:0.5px solid #707070;
}
.desktop-search-column .module-search .form-search .button-close {
     position:absolute;
     top:0;
     right:0;
     font-size:medium;
     padding:0;
}
.desktop-search-column .module-search .form-search .fa.fa-close {
     font-size:16px;
     color:#000;
     padding:10px;
     font-style:normal;
}
/*@media only screen and (max-width:959px) {
     .hide-below-960 {
         display:none !important;
    }
     #mini-cart .hide-below-960 {
         display:inline-block !important;
    }
}
@media only screen and (min-width:960px) {
     .hide-above-960 {
         display:none !important;
    }
}*/
@media only screen and (min-width:768px) {
     .hide-above-768 {
         display:none !important;
    }
}
@media only screen and (max-width:767px) {
     .hide-below-768 {
         display:none !important;
    }
}
.userSwitch a {
     text-indent:-9999px;
     position:absolute;
}
@media only screen and (max-width:959px) {
     .userSwitch a::after {
         content:"\f2c3";
    }
     .userSwitch a::after {
         font-family:fontAwesome;
         font-size:1.5em;
         text-indent:0;
         float:left;
    }
}
@media only screen and (min-width:960px) {
     .userSwitch a::after {
         content:"(Switch customers)";
    }
     .userSwitch a::after {
         text-indent:0;
         float:left;
    }
}
.fa-vcard:before{
     content:"" !important;
}
.fa-vcard:after{
     content:"\f007";
     color:#fff;
}
#inline-search-results {
     right:0;
     left:initial;
}
@media only screen and (min-width:960px) {
     #inline-search-results {
         width:100%;
         right:0px;
         left:initial;
    }
     #inline-search-results .grid12-8.results-right {
         width:68.5%;
    }
     #inline-search-results li.grow {
         width:45%;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     #inline-search-results {
         width:150%;
         left:-30% !important;
         margin:auto;
    }
     #inline-search-results .results-left {
         display:none;
    }
     #inline-search-results .results-right {
         width:100%;
    }
     #inline-search-results .results-right .grid12-4 {
         width:40%;
    }
     #inline-search-results a {
         text-align:center;
    }
     #inline-search-results a .product-desc {
         height:20px;
         line-height:20px;
    }
     #new-advanced-search-box .results-right .products ul li.grow {
         min-height:250px;
    }
}
@media only screen and (max-width:767px) {
     #inline-search-results {
         width:96%;
         left:0 !important;
         margin:auto;
         max-height:700px;
         overflow:auto;
    }
     #inline-search-results li.grow {
         width:45% !important;
    }
}
/*Smaller desktops*/
 @media only screen and (max-width:1599px) {
     .desktop-top-header .logo-col img {
         max-width:300px;
    }
}
@media only screen and (max-width:1399px) {
     .desktop-top-header .logo-col img {
         max-width:250px;
    }
     .desktop-top-header .nav-regular li.level0>a {
         padding:0 6px;
    }
}
@media only screen and (max-width:1199px) {
     .desktop-top-header .logo-col img {
         max-width:200px;
    }
    .desktop-top-header .nav-regular li.level0 > a > span, .top-header-container .links>li>a, .userSwitch a, .welcome-msg, .userOnHold, .header span {
         font-size:12px;
    }
     .desktop-top-header .search-col img{
         width:12px;
         height:12px;
    }
    .cart-col .dropdown-menu *, .cart-col .dropdown-toggle * {
    font-size: 12px;
    }
}
@media only screen and (max-width:1099px) {
     .desktop-top-header .logo-col img {
         max-width:150px;
    }
     
}
@media only screen and (max-width:1019px) {
     .desktop-top-header .nav-regular li.level0 > a > span, .top-header-container .links>li>a, .userSwitch a, .welcome-msg, .userOnHold, .header span {
         font-size:10px;
    }
    .desktop-top-header .logo-col img {
        max-width: 120px;
    }
     
}
@media only screen and (max-width:959px) {
     .desktop-top-header .nav-regular li.level0 > a > span, .top-header-container .links>li>a, .userSwitch a, .welcome-msg, .userOnHold, .header span {
         font-size:14px;
    }
     .desktop-top-header .grid-container>div {
         width:unset !important;
    }
     .desktop-top-header .grid-container {
         justify-content:space-between;
    }
     .desktop-search-column, .desktop-search-column .grid-column-wrapper, .desktop-top-header, .desktop-top-header .cart-col{
         overflow:unset;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .item.item-switch-customer {
         padding-top:10px;
    }
     .item.item-switch-customer * {
         font-size:16px;
    }
     .desktop-top-header .search-col img {
         width:15px;
         height:15px;
         margin-top:8px;
    }
     .desktop-top-header .logo-col img {
         max-width:250px;
    }
     .desktop-top-header .cart-col .feature-icon-hover a .empty {
         display:inline-block;
    }
}
@media only screen and (max-width:767px) {
     .desktop-top-header .hide-above-768 .header-top-container {
         background-color:#fff;
    }
     .desktop-top-header .hide-above-768 span.fa:before, .desktop-top-header .hide-above-768 .fa-vcard:after {
         color:#000;
    }
     .desktop-top-header {
         padding:10px 0px !important;
    }
     .desktop-top-header .logo-col img {
         max-width:150px;
    }
     .desktop-top-header .cart-col .feature-icon-hover a .empty {
         display:none;
    }
     .desktop-top-header .search-col img {
         width:20px;
         height:20px;
         margin-top:3px;
    }
     .desktop-search-column .module-search.search-wrapper {
         width:88%;
         margin:auto;
    }
}


.links>li>a {
     width: initial;
 }