

/**************************[ Footer ]**************************/
.footer-container {
    background-color:#000;
}
.footer-container, .footer-container2, .footer-primary.footer.container, .footer-top{
    background-color:#000;
}
.footer-top-container {
    padding-top:10px;
    margin-bottom:0;
}
.footer-primary-container {
    background-color:transparent;
    padding-top:10px;
    padding-bottom:10px;
}
.footer-bottom-container.section-container {
    background-color:#333;
    color:#999;
     display:none;
}

.footer-logo-col img{
     max-width:250px;
}
.footer-links-col {
     width:51%;
}
.footer-social-col {
     width:20%;
}
.collapsible li a, .policy-links a {
     color:#FFF;
     font-family: 'Roboto-Light';
    font-size: 14px;  
}
.policy-links a {
    font-size: 14px;
    line-height: 1.5;
}
.collapsible h6.block-title {
     color:#FFF;
     font-size:14px;
     font-family:'Roboto-Bold';
     margin-bottom: 0;
     margin-top: .625rem;
}
.collapsible h6.block-title a{
     font-size:14px;
}
.collapsible ul.bullet li {
     background:none;
     padding-left:0;
     margin-bottom:0px;
}
.collapsible ul.bullet li:hover a {
     font-family:'Roboto-Medium';
}
.footer-links-col .item-default > div:nth-child(1) {
     width:26%;
}
.footer-links-col .item-default > div:nth-child(2) {
     width:20%;
}
.social-wrapper{
     min-height:250px;
     display:flex;
     flex-direction:column;
     justify-content:space-between;
}
.social-icons {
     display:flex;
     justify-content:flex-end;
     align-items:center;
     margin-top: 1.2rem;  
}
.social-icons a {
     margin-left:5%;
}
.social-icons img {
     max-width:32px;
}
.policy-links {
     text-align:end;
     display:flex;
     flex-direction:column;
     gap:5px;
}
#scroll-to-top {
     display:none !important;
}
.footer-links-col .item-default > div:nth-child(3) h6.block-title.heading{
     line-height:24px;
}
@media only screen and (min-width:960px) {
    .collapsible h6.block-title {
         margin-top:1.2rem;
    }
}
@media only screen and (max-width:1499px) {
     .social-icons img {
         max-width:28px;
    }
     .social-wrapper {
         min-height:240px;
    }
    /* .collapsible li a, .policy-links a, .collapsible h6.block-title {
         font-size:16px;
    }*/
}
@media only screen and (max-width:1279px) {
     .social-icons img {
         max-width:24px;
    }
     .social-wrapper {
         min-height:240px;
    }
    /* .collapsible li a, .policy-links a, .collapsible h6.block-title {
         font-size:14px;
    }*/
     .footer-logo-col img{
         max-width:200px;
    }
}
@media only screen and (max-width:1099px) {
     .social-icons img {
         max-width:22px;
    }
     .footer-logo-col img{
         max-width:150px;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .footer-links-col {
         width:73% !important;
         margin-left:4%;
    }
     .footer-social-col {
         width:98% !important;
    }
     .footer-social-col .social-wrapper {
         min-height:unset;
         flex-direction:row;
         justify-content:space-around;
    }
     .policy-links {
         flex-direction:row;
    }
     .social-icons a, .policy-links a {
         margin-left:15px;
         margin-top:20px;
    }
    
     .footer-logo-col.grid12-3, .footer-links-col .grid12-3 {
         width:23% !important;
    }
     .footer-links-col .item-default>div:nth-child(1) {
         width:26% !important;
    }
     .footer-links-col .item-default>div:nth-child(2) {
         width:20% !important;
    }
}
@media only screen and (max-width:767px) {
     .footer-logo-col {
         display:none !important;
    }
     .footer-top .section {
         padding:10px 20px;
    }
     .collapsible .opener, .collapsible .opener:hover {
         background-image:url('https://commercebuild-themes.mysagestore.com/static/Windsor/images/infortis/ultra-megamenu/opener-w.png') !important;
         background-color:#000 !important;
    }
     .collapsible.active .opener:hover {
         background-color:#000 !important;
         background-image:url('https://commercebuild-themes.mysagestore.com/static/Windsor/images/infortis/ultra-megamenu/opener-active-w.png') !important;
    }
     .social-wrapper {
         min-height:unset;
         flex-direction:column-reverse;
         justify-content:center;
         gap:20px;
    }
     .social-icons {
         justify-content:center;
    }
     .policy-links {
         text-align:center;
         gap:0px;
    }
    /* .collapsible li a, .policy-links a, .collapsible h6.block-title {
         font-size:16px;
    }*/
     .social-icons img {
         max-width:28px;
    }
}
 

