@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

/**************************[ BASE ]**************************/
body {
	font-family:'Roboto-Light';
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	color: #000;
	background-color: #fff;
}

a, body, i, p, span, u {
	font-size: 18px;
	line-height: 24px;
}
h1 {
	font-size:24px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family:'Roboto-Medium';
	color:#000;
}
h2 {
	font-size:16px;
	letter-spacing:0;
	text-transform: none !important;
	line-height:20px;
	font-family:'Roboto-Medium';
}
h3 {
	font-size:16px;
	line-height:inherit;
	font-family:'Roboto-Medium';
}
h4 {
	font-size:16px;
	font-family:'Roboto-Light';
}
h6 {
	font-size:18px;
	letter-spacing:0px;
	text-transform:uppercase;
	font-family:'Roboto-Light';
}
.wrapper {
	border-top:none;
}

#opc-login h3, #subscribe-form label, .accordion .heading, .block .block-title, .box-account .box-head h2, .caption .heading, .feature .heading, .footer .heading, .gen-tabs .tabs a, .main-font, .mobnav-trigger, .nav-mobile li.level0>a, .nav-mobile li.level1>a, .nav-mobile li.level2>a, .nav-regular .nav-submenu--mega>li>a, .nav-regular li.level0>a, .order-items .order-comments h2, .order-items h2.sub-title, .order-items h2.table-caption, .product-view .box-reviews dt .heading, .products-grid .product-name, .products-list .product-name, .section-title, body, h1, h2, h3, h3.product-name, h4, h5
{
	font-family:'Roboto-Light';
}

.gen-tabs .tabs a.current {
	color:#000;
}

/**************************[ HEADER ]**************************/
.header a, .header p, .header span {
	font-size: 14px;
	color: #fff;
	font-family:'Roboto-Light';
}
.header .dropdown, .header-top {
	line-height: normal;
}

/* Top bar */
.header-container {
	background-color: #fff;
	padding-bottom: 0;
}
.header-top-container {
	background-color:#000;
	box-shadow:none;
}

.header-top-container .header-top {
	line-height: normal;
}
.welcome-msg *, .userCode, .userName {
	color: #fff !important;
}

/* Logo */
.logo img {
	max-width: 250px;
}

/* Search bar */
.search-wrapper-centered .form-search {
	position: relative;
}
.search-wrapper-centered .form-search .input-text {
	width: 100%;
	background-color: #fff;
	border-color: #999;
	color: #777;
	font-family:'Roboto-Light';
	display: block;
}
.search-wrapper-centered .form-search .button-search {
	position: absolute;
	top: 0;
	right: 0;
}
.search-wrapper-centered .form-search .button-search .fa {
	font-size: 16px;
	color: #777;
	padding: 10px;
}
.search-wrapper-centered .form-search-globalfilter {
	position: relative;
}
.search-wrapper-centered .form-search-globalfilter label[for="ignore-filter-ignore"] {
	font-size: 12px;
}

/* Cart Widget */
.user-menu, .user-menu a {
	color: #fff;
}
.dropdown-toggle *, .header span.cart-total, .header span {
	color: #000;
}
.dropdown-toggle .value {
	color: #fff;
}
.dropdown.open .dropdown-toggle .value {
	color: #000;
}
.header .open > .dropdown-toggle.cover > div {
	background-color: transparent;
}
.feature-icon-hover .caret {
	/* padding-top: 10px;
	font-weight: 700;
	border-top-color: #000; */
	border-width: 6px 5px 0;
}
.dropdown-toggle .icon.close-to-text {
	display: none;
}
.dropdown-toggle .hide-below-1280,
.dropdown-toggle .amount {
	padding: 0.5em 5px;
	font-size: 14px;
}
.empty span.price {
	margin-top: 5px;
	padding-right: 0.5em;
}
.header .fa.fa-shopping-cart {
	position: relative;
	top: 8px;
	left: -15px;
	font-size: 18px;
}
#root-wrapper span.fa {
	font-size: 18px;
}

/* Menu */
.custom-freeshipping-note a, .links > li > a, .nav-regular li.level0 > a > span {
	color: #000;
	font-size: 14px;
	font-family:'Roboto-Light';
}
.links > li > a:hover {
	background-color: #000;
	color: #ffffff !important;
}
#nav {
	margin-top: 0;
	margin-bottom: 0;
}
#nav, .mobnav-trigger {
	background-color: #fff;
	border-bottom: none;
}
.nav-regular .nav-item.level0:hover > a,
.nav-regular .nav-item.level0:hover > a > span,
.nav-mobile .nav-item.level0 > a:hover {
	background-color: transparent;
	color: #000; 
    font-weight:500;
}
.nav-regular li.level0 > .nav-panel--dropdown, .nav-regular .mega > .nav-panel--dropdown, .nav-regular .classic > .nav-panel--dropdown {
	border-top: 5px solid #000;
}
#nav .nav-panel--dropdown a {
	color: #777;
	font-size: 14px;
}
#nav .nav-panel--dropdown a > span {
	font-size: 14px;
}
#nav .nav-panel--dropdown a:hover {
	color: #000;
}
.mobnav-trigger.active {
	background-color: #000;
}
.cms-index-index .nav-regular .nav-item--home > a, .nav-regular .nav-item.level0.active > a, .nav-mobile .nav-item.level0.current > a {
	background-color: transparent;
	color: #777;
}
#nav .nav-item--home .fa-home {
	color: #000 !important;
	padding: 0.3rem;
}
#root-wrapper a:hover span.fa:before {
	color: #000;
}
#nav .nav-item.level0.parent:hover > a .caret {
	border-top-color: #000;
}
#nav .nav-panel--dropdown a:hover .caret {
	border-left-color: #000;
	border-right-color: #000;
}
#mini-cart .actions button {
	width: 100%;
}
#mini-cart .actions button.button span {
	background-color: transparent !important;
	width: 100%;
}
#mini-cart .actions button.button span span {
	background-color: #000 !important;
	padding: 0;
	border-radius: 0;
	max-height: 45px;
	font-size: 16px;
	margin-top: 10px;
    color: #fff !important;
    text-transform: uppercase;
}
#mini-cart .actions button.button span span:hover {
	background-color: #777 !important;
}
.dropdown-menu > li * {
	float: none;
	display: inline-block;
}
.dropdown-menu > li > a {
	display: inline-block;
}

/**************************[ BODY ]**************************/
.breadcrumbs a, .breadcrumbs li {
	font-size: 14px;
}
.breadcrumbs li span {
	margin: 10px 4px 0;
	font-size: 11px;
	letter-spacing: 2px;
	text-transform: uppercase;
}
.breadcrumbs li span.breadcrumb-separator {
	background: url(../images/img/pix.png) 0 -129px no-repeat;
	width: 7px;
	height: 5px;
	display: block;
	float: left;
	text-indent: -9999px;
	overflow: hidden;
	margin: 12px 4px 0;
}
h1, h1.banner-h1, .banner-h1 a {
	font-size: 32px;
	color: #000;
	letter-spacing: 3px;
	text-transform: uppercase;
}
.pager .amount, .sorter .amount {
	line-height: 36px;
}
.toolbar label, .toolbar p, .toolbar span {
	font-size: 14px;
}
.products-grid.single-line-name .product-name {
	overflow: visible;
	text-overflow: initial;
	word-wrap: break-word;
	white-space: normal;
}
.products-grid.centered .item {
	font-size: 14px;
	line-height: 20px;
}
.products-grid .product-name, h3.product-name a {
	font-size: 16px;
}
.bootstrap-styles h2 {
	letter-spacing: 0;
}
.product-view .btn-cart span, button.button span {
	color: #fff;
}
.add-cart-wrapper button span span {
	width: 140px;
	max-width: 140px;
}
.product-view .btn-cart span, button.button span {
	margin: 0;
	/* background-color: #000; */
	border-radius: 0;
	padding: 0;
}
button, .button, .button a, button a {
	font-family:'Roboto-Light';
	font-size: 16px;
}
button.button span {
	font-size: 16px;
}

.add-cart-wrapper button:last-child {
	margin-top: 10px;
}
/* .btn-filled:hover, .button:hover span {
	background-color: #777 !important;
} */
.products-grid.centered .item {
	line-height: 20px;
}
.item .price-box .price, .item .price-box .price span, .xm-owl-carousel .price-box .price, .xm-owl-carousel .price-box .price span {
	font-size: 14px;
	color: #000;
}
.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
	color: #000;
}
.main .col-main, .main .sidebar {
	margin-top: 1%;
	margin-bottom: 1%;
}
.account-login {
	margin: 15px 0 0 0;
	background-color: #fff;
}
.block-title span {
	font-size: 20px;
}
.form-list label {
	font-weight: unset;
}
div[class^='account'] p, div[class^='account'] h2 {
	text-align: left;
}

div[class^='account'] .form-list input.input-text {
	float: left;
}
.category-title, .my-account .page-title {
	border-bottom: none;
}
.pager .pages li a {
	background-color: #f5f5f5;
}
.pager .pages li.current,
.pager .pages li a {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

/**************************[ Footer ]**************************/



/************************** MICS **************************/
.std i {
     font-style:normal;
}
.page-content ul, #product-tabs .std ul, .product-shop.module-product-details ul:not(.add-to-links) {
     list-style:disc;
     margin-left:25px;
}
#header {
     position:sticky;
     top:0;
     z-index:50;
}
@media print {
     .ord-details .grid-column-wrapper {
         width:98%;
    }
     .module-trans-details-listing {
         max-width:95%;
    }
     .module-order-details-listing {
         max-width:95%;
    }
     @page {
         size:landscape 
    }
}
.isPublic .hide-for-B2C {
     display:none;
}
body:not(.isPublic) .hide-for-B2B {
     display:none;
}
input.input-text, select, textarea {
     font-family:'Roboto-Light';
     font-size:16px;
}
input.input-text[disabled=disabled], input[disabled=disabled], input.input-text[disabled=disabled]::placeholder, input[disabled=disabled]::placeholder {
     color:#363636 !important;
     -webkit-text-fill-color:rgba(0, 0, 0, 0.8);
     -webkit-opacity:0.8;
}

/*Content pages*/

/*Projects page:starts*/
.col-main.top-banner{
     width:100%;
     margin:0 !important;
}
.projects-page.main, .indiv-prj-page.main, .technical-page.main, .trade-page.main, .category-pages.main, .category-landing.main, .about-page.main, .hub-page.main, .retailers.main, .contact-us-page.main {
     margin-top:0;
     margin-bottom:0;
}
/*Banner for content pages*/
 .resp-carousel img, .resp-carousel video, .resp-carousel iframe{
     filter:brightness(0.5);
     object-fit:cover;
}
.banner-content {
     position:absolute;
     top:0;
     left:0;
     height:100%;
     width:100%;
}
.banner-content > p, .banner-content br{
     display:none;
}
.banner-content > div.banner-txt {
     top:30%;
     width:100%;
     position:relative;
     text-align:center;
}
.projects-page-resp .banner-content > div.banner-txt {
     top:30%;
}
.banner-content div h1 {
     text-transform:Capitalize;
     color:#fff;
     font-family:'Roboto-Thin';
     font-size:3rem;
     letter-spacing:.05em;
     line-height:1.25;
}
.banner-content .banner-p-txt {
     padding:3rem;
     max-width:48rem;
     width:100%;
     font-size:1rem;
     line-height:1.5;
     font-family:'Roboto-Light';
     color:#fff;
     margin:auto;
}
.banner-content .banner-p-txt p {
     font-size:1rem;
     line-height:1.5;
     font-family:'Roboto-Light';
     color:#fff;
}
.banner-content div p {
     margin-bottom:1rem;
}
@media only screen and (max-width:767px) {
     .banner-content div h1 {
         font-size:2.25rem;
    }
     .mobile-banner-txt .mb-txt {
         padding-top:6rem;
         text-align:center;
    }
     .mobile-banner-txt .mb-txt p {
         font-size:1rem;
         font-family:'Roboto-Light';
         margin-bottom:1rem;
    }
     .banner-content .banner-p-txt {
         display:none;
    }
}
/*Banner for content pages:end*/
.projects-page .grid-full.breadcrumbs, .indiv-prj-page .grid-full.breadcrumbs, .trade-page .grid-full.breadcrumbs, .about-page .grid-full.breadcrumbs, .hub-page .grid-full.breadcrumbs, .retailers .grid-full.breadcrumbs, .finishes-page .grid-full.breadcrumbs, .contact-us-page .grid-full.breadcrumbs, .careers-page .grid-full.breadcrumbs{
     display:none;
}
.view-all-prjs .nested-container, .get-the-look .nested-container, .indiv-prj-page .nested-container, .hot-deals-prj .nested-container{
     width:90%;
}
.view-all-prjs .grid-container, .get-the-look .grid-container {
     align-items:baseline;
}
.view-all-prjs .grid-container .grid-column-wrapper, .get-the-look .grid-container .grid-column-wrapper{
     margin-top:1%;
     margin-bottom:1%;
}
.view-all-prjs .row-title.section-title, .get-the-look .row-title.section-title, .look-book-section .row-title.section-title{
     text-transform:inherit;
     font-family:'Roboto-Thin';
     text-align:center;
     border:none;
     font-size:2.25rem;
     line-height:1.25;
}
.view-all-prjs .row-title.section-title span, .get-the-look .row-title.section-title span, .look-book-section .row-title.section-title span{
     font-size:2.25rem;
     line-height:1.25;
}
.view-all-prjs.prj-links-tabs .grid-column-wrapper {
     margin-top:1.5rem;
     margin-bottom:0;
}
.anchor-links-prjs ul {
     list-style:none;
     display:flex;
     flex-direction:row;
     justify-content:center;
     flex-wrap:wrap;
     max-width:48rem;
     margin:auto;
     border-bottom:1px solid #e2e8f0;
}
.anchor-links-prjs ul li {
     font-size:1rem;
     line-height:1.5;
     padding:0 0 .5rem;
     cursor:pointer;
     flex:1 1 auto;
     text-align:center;
     margin-right:0.5rem;
}
@media only screen and (min-width:768px) {
     .anchor-links-prjs ul li.state-selected {
         border-bottom:3px solid #951B4A;
    }
}
@media only screen and (max-width:767px) {
     .anchor-links-prjs ul li {
         padding:1rem 1.5rem .5rem;
    }
     .anchor-links-prjs ul li.state-selected {
         color:#951B4A;
    }
     .anchor-links-prjs ul {
         flex-direction:column;
    }
}
.indiv-prj .prj-img img {
     object-fit:cover;
     width:100%;
}
.prj-details > p, .look-details > p{
     margin:0;
}
.prj-details > p > a, .prj-details > p > a:hover, .look-details > p > a, .look-details > p > a:hover{
     color:#000;
}
.prj-details > p > a, .look-details > p > a{
     font-size:0.7rem;
     letter-spacing:.05em;
     padding-top:.25rem;
     font-family:'Roboto-Regular';
}
.prj-details > p > a > span, .prj-details > p:last-child > a, .look-details > p > a > span{
     font-size:11px !important;
     padding-right:0.2rem;
     text-transform:uppercase;
     color:#951B4A;
     font-family:'Roboto-Medium';
}
.prj-details > p:nth-child(1){
     padding-top:.75rem;
}
 .prj-details > p:nth-child(1) > a, .look-details > p:nth-child(1) > a{
     letter-spacing:.05em;
     text-transform:uppercase;
     font-size:.875rem;
     font-family:'Roboto-Medium';
}
.look-details > p:nth-child(1) {
     margin-top:.5rem;
     margin-bottom:1rem;
}
.look-details > p:nth-child(1) > a{
     font-size:1rem;
     font-family:'Roboto-Medium';
}
.prj-details > p:last-child > a:hover{
     text-decoration:underline;
     text-underline-offset:3px;
}
.indiv-look {
     position:relative;
}
.look-view {
     position:absolute;
     top:45%;
     left:50%;
     transform:translate(-50%, -45%);
}
.look-view span {
     padding:10px 20px;
     background-color:#fff;
     border-radius:20px;
     color:#000;
     text-transform:uppercase;
     font-family:'Roboto-Bold';
     font-size:12px;
}
.indiv-look .look-view {
     display:none;
}
.indiv-look:hover .look-view {
     display:block;
}
@media only screen and (max-width:1023px) {
     .view-all-prjs .grid-container .grid-column-wrapper.grid12-6 {
         width:98%;
    }
     .view-all-prjs .grid-container .grid-column-wrapper.grid12-3 {
         width:48%;
    }
}
@media only screen and (max-width:767px) {
     .view-all-prjs .grid-container .grid-column-wrapper, .get-the-look .grid-container .grid-column-wrapper {
         margin-bottom:30px;
    }
}
/*Projects page:end*/

/*Individual project pages:start*/
.indiv-outer-banner {
     position:relative;
}
.indiv-outer-banner img {
     object-fit:cover;
     width:100%;
}
.indiv-outer-banner .indiv-inner-txt {
     position:absolute;
     top:50%;
     width:100%;
     text-align:center;
     transform:translateY(-50%);
}
.indiv-outer-banner .indiv-inner-txt span {
     font-size:50px;
     line-height:30px;
     color:#fff;
     text-transform:uppercase;
     font-family:'Roboto-Medium';
}
@media only screen and (max-width:767px) {
     .indiv-outer-banner .indiv-inner-txt span {
         font-size:2.25rem;
    }
}
.indiv-prj-page .grid-group.grid-group-1 .grid-container-wrapper {
     margin-top:60px;
}
.indiv-prj-page .prj-content h2 {
     text-transform:inherit;
     color:#000;
     padding-top:3rem;
     margin-top:1.25rem;
     margin-bottom:1.25rem;
     line-height:1.25;
     font-size:32px;
     font-family:'Roboto-Thin';
}
.indiv-prj-page .prj-content h4 {
     text-transform:Capitalize;
     font-family:'Roboto-Medium';
     font-size:18px;
     line-height:23px;
     margin-top:.5rem;
     margin-bottom:.5rem;
}
.indiv-prj-page .prj-content .prj-details > p{
     font-size:18px;
     padding-top:0;
     font-family:'Roboto-Medium';
     margin-bottom:10px;
}
.indiv-prj-page .prj-content .prj-details > p > span{
     font-size:18px !important;
     padding-right:0.2rem;
     font-family:'Roboto-Light';
     display:block;
}
.indiv-prj-page .prj-para p {
     font-size:18px;
     font-family:'Roboto-Light';
     margin-top:0;
     line-height:23px;
     margin-bottom:1rem;
}
.indiv-prj-page .prj-content > .prj-para:nth-child(2) {
     margin-top:80px;
}
.indiv-prj-page .prj-content > .prj-para {
     margin-bottom:2rem;
     margin-top:.5rem;
}
.indiv-prj-page .prj-para .sub-txt{
     font-family:'Roboto-Light';
     font-size:18px;
     display:block;
     margin-top:15px;
     line-height:23px;
}
.project-container .prj-imgs > div{
     margin-bottom:4%;
}
.project-container .prj-imgs > div:last-child{
     margin-bottom:0;
}
 .indiv-prj-page .grid-group-1 .grid-container > div.grid-column-wrapper:not(.first) {
     margin-top:2%;
     margin-bottom:0;
}
 .indiv-prj-page .img-container {
     margin-top:2%;
     margin-bottom:0;
}
.indiv-prj-page .grid-column-wrapper >.page-content >div >img {
     object-fit:cover;
     width:100%;
}
.project-container .prj-imgs > div img{
     aspect-ratio:6 / 5;
     object-fit:cover;
     width:100%;
}
@media only screen and (max-width:1279px) {
     .indiv-prj-page .nested-container, .hot-deals-prj .nested-container{
         width:100%;
    }
}
@media only screen and (min-width:1140px) {
     .indiv-prj-page .project-container {
         display:flex;
    }
     .indiv-prj-page .project-container .prj-content{
         width:40%;
    }
     .indiv-prj-page .project-container .prj-imgs{
         margin-left:5%;
         flex:1 
    }
}
/*Hot deals on project pages*/
 .indiv-prj-page h3.section-title.padding-right {
     text-transform:capitalize;
     letter-spacing:0;
     font-family:'Roboto-Medium';
     border:none;
     padding:0;
     padding-left:10px;
}
.indiv-prj-page h3.section-title.padding-right span {
     font-size:18px;
     line-height:23px;
}
 .indiv-prj-page .itemslider-wrapper .item {
     padding-top:5px;
}
 .indiv-prj-page .itemslider-wrapper .item .product-image-wrapper{
     border:1px solid #000;
}
.indiv-prj-page .itemslider-wrapper .item > *:not(:first-child) {
     display:none;
}
.indiv-prj-page .itemslider-wrapper .item .button.quickviewbutton {
     display:none !important;
}
@media only screen and (max-width:479px) {
     .indiv-prj-page .itemslider .owl-pagination {
         display:block !important;
    }
}
/*Individual project pages:ends*/

/*Common style for content pages*/
.content-pages .grid-full.breadcrumbs{
     display:none;
}
.content-pages .content-section {
     margin-top:0;
     margin-bottom:0;
}
/*Technical page:starts*/
@media only screen and (max-width:767px) {
     .technical-page.main.container {
         max-width:none !important;
         width:100%;
         padding-left:0%;
         padding-right:0%;
    }
}
.content-section {
     padding-top:2.5rem;
     padding-bottom:2.5rem;
}
.content-section-txt {
     padding-left:6rem;
     padding-right:6rem;
}
.content-section-txt h2 {
     text-align:center;
     margin-top:1.5rem;
     margin-bottom:1.5rem;
     line-height:1.25;
     font-size:2.25rem;
     font-family:'Roboto-Thin';
}
.technical-page .collapse-expand-container{
     width:100%;
     align-items:center;
     flex-direction:column;
     display:flex;
}
.technical-page .collapse-expand-section {
     margin-bottom:-1px !important;
     width:100%;
     cursor:pointer;
     position:relative;
}
.technical-page .collapse-expand-section .plus-minus{
     color:#951B4A;
     border-top:1px solid #951B4A;
     border-bottom:1px solid #951B4A;
     font-size:1.125rem;
     text-transform:uppercase;
     justify-content:space-between;
     display:flex;
     align-items:center;
}
.technical-page .collapse-expand-section .plus-minus h4 {
     font-family:'Roboto-Medium';
     padding-top:.5rem;
     padding-bottom:.5rem;
     text-transform:uppercase;
     font-size:18px;
}
@media only screen and (max-width:767px) {
     .technical-page .collapse-expand-section .plus-minus h4 {
         font-size:16px;
    }
}
.technical-page .collapse-expand-section .plus-minus .signs {
     position:relative;
}
.technical-page .collapse-expand-section .subsection-hdng {
     text-transform:uppercase;
     color:#951B4A;
     font-size:.875rem;
     font-family:'Roboto-Bold';
}
.technical-page .collapse-expand-section .prod-list-subsection:first-child {
     margin-top:1rem;
}
.technical-page .collapse-expand-section .prod-list-subsection:last-child {
     margin-bottom:.5rem;
}
.technical-page .collapse-expand-section .subsection-hdng .fa{
     margin-right:1rem;
     font-size:1rem;
}
.technical-page .collapse-expand-section .prod-list-child-grid {
     padding:1rem 0 2rem 0;
     max-height:100rem !important;
     gap:1.5rem;
     display:grid;
     width:100%;
}
@media only screen and (min-width:640px) {
     .technical-page .collapse-expand-section .prod-list-child-grid {
         grid-template-columns:repeat(2, minmax(0, 1fr));
    }
}
@media only screen and (min-width:768px) {
     .technical-page .collapse-expand-section .prod-list-child-grid {
         grid-template-columns:repeat(3, minmax(0, 1fr));
    }
}
@media only screen and (min-width:1024px) {
     .technical-page .collapse-expand-section .prod-list-child-grid {
         grid-template-columns:repeat(4, minmax(0, 1fr));
    }
}
@media only screen and (max-width:639px) {
     .technical-page .collapse-expand-section .prod-list-child-grid {
         grid-template-columns:repeat(1, minmax(0, 1fr));
    }
}
.prod-list-child-grid .flex-col{
     display:flex;
     flex-direction:column;
     gap:0;
     justify-content:space-between;
}
.prod-list-child-grid .flex-col .img-and-txt img {
     height:12rem;
     width:12rem;
     object-fit:cover;
     min-height:12rem;
}
.prod-list-child-grid .flex-col .img-and-txt h5{
     min-height:2.5rem;
     letter-spacing:.05em;
     text-transform:uppercase;
     padding-top:.75rem;
     padding-bottom:.75rem;
     font-size:1rem;
     font-family:'Roboto-Medium';
}
.prod-list-child-grid .flex-col .img-and-txt .txt-code {
     margin-bottom:1rem;
     font-size:.875rem;
}
.prod-list-child-grid .flex-col .txt-link{
     gap:.5rem;
     justify-content:flex-start;
     flex-direction:column;
     display:flex;
     flex:1;
}
.prod-list-child-grid .flex-col .txt-link .catalogue-buttons a {
     color:#951B4A;
     font-size:1rem;
     display:block;
     font-family:'Roboto-Medium';
}
.prod-list-child-grid .flex-col .txt-link .catalogue-buttons a:hover {
     text-decoration:underline;
}
.technical-page .disclaimer-txt p{
     font-size:.875rem;
     margin-bottom:1rem !important;
     font-family:'Roboto-Regular';
}
.technical-page .dwnld-btn {
     display:flex;
     flex-direction:row;
     flex-wrap:wrap;
     justify-content:start;
     text-align:center;
     margin-bottom:1.5rem;
}
.technical-page .dwnld-btn a{
     background-color:#951B4A;
     padding-left:1.2rem;
     padding-right:.7rem;
     padding-top:.45rem;
     margin:1.2rem .5rem 0 0;
     display:flex;
     padding-bottom:.45rem;
     justify-content:center;
     align-items:center;
     box-sizing:border-box;
     color:#fff;
     font-size:1rem;
}
.technical-page .dwnld-btn a svg {
     font-size:1.25rem;
     margin-left:.5rem;
}
.text-warranty-list {
     font-size:.875rem;
}
.text-warranty-list ul li{
     list-style-type:none !important;
     padding-bottom:1rem;
     display:flex;
     -webkit-box-align:start;
     align-items:start;
     font-family:"Roboto", sans-serif !important;
     font-size:.875rem;
     font-family:'Roboto-Regular';
     line-height:1.5;
}
.text-warranty-list ul li:before {
     content:url(https://cdn-5c84bc36-b681cbc1.commercebuild.com/8a7e8814cdea9f9f6b2660923b84adaf/contents/ckfinder/images/au-list-arrow.png);
     display:inherit;
     font-size:0px;
     vertical-align:middle !important;
}
.text-warranty-list ul {
     margin-left:0;
}
.warranty-subhead {
     margin-bottom:.5rem;
     font-size:1rem;
     font-family:'Roboto-Medium';
     text-transform:capitalize;
}
@media only screen and (max-width:959px) {
     .content-section-txt {
         overflow-y:hidden;
         padding-left:3rem;
         padding-right:3rem;
    }
}
@media only screen and (max-width:767px) {
     .content-section-txt {
         padding-left:1.5rem;
         padding-right:1.5rem;
    }
}
/*technical page ends*/

/*News:start*/
.news-page, .news-page .grid-group-1 .grid-container-wrapper, .news-page .grid-group-1 .grid-container-wrapper .grid-column-wrapper, .news-page .grid-group-2 .grid-container-wrapper, .news-page .grid-group-2 .grid-container-wrapper .grid-column-wrapper {
     width:100%;
     margin-left:0;
     margin-right:0;
     max-width:100% !important;
}
.news-page .grid-group-1 .grid-container-wrapper {
     padding-bottom:8rem;
}
.news-page .grid-group-2 .grid-container-wrapper {
     padding-top:2.5rem;
}
.w-full {
     width:100%;
}
.object-cover {
     object-fit:cover;
}
.h-64 {
     height:16rem;
}
.flex-shrink-0 {
     flex-shrink:0;
}
.news-container{
     display:grid;
     grid-template-columns:repeat(1, minmax(0, 1fr));
     gap:3rem;
}
.news-content {
     position:relative;
     padding-left:1.5rem;
     padding-right:1.5rem;
}
.news-content-section {
     padding-left:1.5rem;
     padding-right:1.5rem;
     position:relative;
}
.news-content-section h1 {
     line-height:1.25;
     margin-top:1.5rem;
     margin-bottom:1.5rem;
     font-size:2.25rem;
     font-family:'Roboto-Thin';
     text-transform:inherit;
     letter-spacing:0;
}
.news-content-section .news-date {
     text-transform:uppercase;
     margin-top:1rem;
     font-size:1rem;
     line-height:1.5;
}
.news-txt {
     margin-top:1.5rem;
     font-size:.875rem;
}
.news-txt p {
     margin-bottom:1rem;
     font-size:.875rem;
}
.news-tiles-section h1{
     font-size:2.25rem;
     font-family:'Roboto-Thin';
     text-align:center;
     text-transform:inherit;
     letter-spacing:0;
}
.news-tiles-container {
     width:100%;
     margin-top:1rem;
     display:flex;
     padding-left:1.5rem;
     padding-right:1.5rem;
     box-sizing:border-box;
}
.news-tiles-grid {
     display:grid;
     margin-top:4rem;
     width:100%;
     gap:2rem;
     grid-template-columns:repeat(1, minmax(0, 1fr));
}
.news-item {
     display:flex;
     flex-direction:column;
     justify-content:space-between;
}
.news-item .caption-txt {
     letter-spacing:.05em;
     text-transform:uppercase;
     padding-top:.75rem;
     font-size:.875rem;
     font-family:'Roboto-Medium';
}
.news-item .news-date {
     font-size:0.8rem !important;
     letter-spacing:.05em;
     text-transform:uppercase;
}
.news-item .read-more-button a {
     font-size:0.8rem !important;
     margin-top:.5rem;
     display:block;
     color:#000;
}
.news-item .read-more-button a:hover {
     text-decoration:underline;
}
@media only screen and (min-width:768px) {
     .news-content-section h1, .news-tiles-section h1 {
         font-size:3rem;
    }
     .news-tiles-grid {
         grid-template-columns:repeat(2, minmax(0, 1fr));
    }
}
@media only screen and (min-width:1024px) {
     .news-container {
         grid-template-columns:repeat(2, minmax(0, 1fr));
    }
     .news-tiles-grid {
         grid-template-columns:repeat(4, minmax(0, 1fr));
    }
     .news-tiles-container {
         padding-left:6rem;
         padding-right:6rem;
    }
}
/*News:end*/

/*Category Listing:start*/
.category-listing {
     width:90% !important;
     margin-left:5%;
     margin-right:5%;
}
.category-listing .grid-container {
     align-items:unset;
}
.category-listing .module-category-misc .sort-by label, .category-listing .column-title.section-title {
     font-family:'Roboto-Bold';
     font-size:20px;
     /*23px;
     */
     line-height:30px;
     text-transform:Capitalize;
}
.category-listing .module-category-misc .sort-by select{
     border:none;
     border-bottom:1px solid #000;
     padding:8px 0;
     font-family:'Roboto-Light';
     color:#000;
     font-size:20px;
     /*23px;
     */
     line-height:50px;
     height:auto;
     width:120px;
     margin-left:10px;
}
.category-listing .module-category-misc .sort-by select[name="sort_by"] option:nth-last-child(2),.category-listing .module-category-misc .sort-by select[name="sort_by"] option:nth-last-child(1) {
     display:none;
}
.toolbar-section .module-category-misc {
     justify-content:end;
}
#filter_list_box {
     margin-top:0;
}
.sidebar.sidebar-filter {
     margin-top:0;
}
.sidebar.sidebar-filter .block-content {
     padding-top:0;
}
.sidebar.sidebar-filter .block-content > div:nth-child(1) {
     display:none;
}
#filter_list_box dt, #filter_list_box .fa-angle-down:before, #filter_list_box .fa-angle-down.filter-collapsible.up:before, #mm-filter_list_box dt, #mm-filter_list_box .fa-angle-down:before, #mm-filter_list_box .fa-angle-down.filter-collapsible.up:before{
     font-family:'Roboto-Light';
     color:#000;
     font-size:20px;
     /*23px;
     */
     line-height:50px;
     font-weight:300;
}
#filter_list_box .fa-angle-down, #mm-filter_list_box .fa-angle-down {
     transform:none;
}
#filter_list_box .fa-angle-down:before, #mm-filter_list_box .fa-angle-down:before {
     content:"x";
     font-size:18px;
}
#filter_list_box .fa-angle-down.filter-collapsible.up:before, #mm-filter_list_box .fa-angle-down.filter-collapsible.up:before {
     content:"+";
     font-size:18px;
}
#filter_list_box dd {
     padding-left:0;
     margin-bottom:40px;
}
#filter_list_box dd ol li, #mm-filter_list_box dd ol li{
     margin-bottom:7px;
}
#filter_list_box dd ol li a, #mm-filter_list_box dd ol li a {
     font-family:'Roboto-Light';
     font-size:20px;
     line-height:30px;
     display:flex;
     align-items:center;
     gap:10px;
     color:#000;
}
#filter_list_box .fa.fa-check-square-o, #filter_list_box .fa.fa-square-o {
     font-size:18px;
}
.sidebar .block-content #filter_list_box dd ol {
     max-height:unset !important;
}
#filter_list_box dt.addline, #mm-filter_list_box dt.addline {
     text-decoration:underline;
}
#listing-ul .item {
     margin-top:20px;
}
#listing-ul .item a.product-image img {
     border:1px solid #707070;
     object-fit:cover;
     box-sizing:border-box;
     width:100%;
     height:100%;
}
#listing-ul .item span.item-code {
     display:none;
}
#listing-ul .item h2.product-name a {
     font-family:'Roboto-Light';
     font-size:20px;
     /*23px;
     */
     line-height:30px;
     color:#000;
}
.module-category-misc .pager {
     border:none;
}
.pager .pages li a, .pager .pages li a:hover, .pager .pages li.current {
     color:#000;
     background-color:#fff;
}
.pager .pages strong {
     font-family:'Roboto-Bold';
     font-size:20px;
     /*23px;
     */
     line-height:30px;
}
.pager .pages li.current, .pager .pages li:hover a{
     border-bottom:1px solid #000;
     box-sizing:border-box;
}
.pager .pages li a, .pager .pages li.current {
     width:36px;
     height:36px;
     line-height:36px;
}
@media only screen and (max-width:959px) {
     .toolbar-section .module-category-misc {
         justify-content:space-between;
    }
     .category-listing .module-category-misc .sort-by select {
         padding-top:0;
    }
     .mh-filters-btn .fa-sliders:before {
         content:"Filter + ";
         font-family:'Roboto-Bold';
         font-size:20px;
         line-height:30px;
         color:#000;
    }
     .mh-filters-btn .fa-sliders{
         padding:0 !important;
    }
     .sidebar-wrapper.module-category-filters .mmenu-target-categories{
         display:none;
    }
     .mm-menu_opened.sidebar-wrapper .block-content dd {
         padding-left:5%;
    }
     #mm-filter_list_box dd {
         margin-bottom:40px;
    }
     .sidebar-wrapper.module-category-filters .mm-panels, .mmenu-target-filter .mm-navbar.mm-navbar_sticky {
         background-color:#fff !important;
         border:1px solid #ccc;
    }
     .mmenu-target-filter .mm-navbar__title span, .pager .pages strong, .category-listing .module-category-misc .sort-by label, .category-listing .column-title.section-title{
         font-family:'Roboto-Bold';
         font-size:20px;
         line-height:30px;
         color:#000;
    }
     #mm-filter_list_box dt {
         font-size:20px;
         line-height:47px;
         display:flex;
         justify-content:space-between;
    }
     #mm-filter_list_box dd ol li a {
         font-size:18px;
         line-height:28px;
    }
     #filter_list_box .fa-angle-down.filter-collapsible {
         font-size:1em !important;
    }
     .mm-menu_opened.sidebar-wrapper .block-content dt {
         padding-left:5% !important;
         padding-right:5% !important;
    }
     .category-listing .module-category-misc .sort-by select {
         font-size:20px;
    }
}
/*Category Listing:ends*/
/*1st level category landing page:starts*/
.cat1-banner {
     position:relative;
}
.cat1-banner img {
     object-fit:cover;
     width:100%;
     max-height:550px;
}
.cat1-inner-txt {
     position:absolute;
     top:50%;
     text-align:center;
     left:50%;
     transform:translate(-50%, -50%);
}
/*.cat1-banner.rooms .cat1-inner-txt {
     text-align:left;
     left:43%;
     transform:translate(-50%);
}
*/
.cat1-inner-txt span {
     font-size:46px;
     line-height:30px;
     color:#fff;
     text-transform:inherit;
     font-family:'Roboto-Thin';
}
.cat1-banner-subtxt span {
     font-family:'Roboto-Light';
     font-size:20px;
     line-height:24px;
}
.cat1-banner-subtxt{
     max-width:48rem;
     text-align:center;
     margin:2rem auto;
}
.sub-cat-tiles {
     display:grid;
     grid-template-columns:repeat(4, 1fr);
     align-items:center;
     justify-content:center;
     gap:4rem 1rem;
}
.sub-cat-tiles div {
     text-align:center;
}
.sub-cat-tiles img {
     box-sizing:border-box;
     object-fit:cover;
     width:100%;
     background-color:#F3F3F3;
}
.sub-cat-tiles > div > div {
     font-size:23px;
     font-family:'Roboto-Light';
     line-height:30px;
     margin-top:10px;
}
.sub-cat-tiles a {
     color:#000;
}
@media only screen and (max-width:959px) {
     .sub-cat-tiles {
         grid-template-columns:repeat(3, 1fr);
    }
     .cat1-banner-subtxt{
         padding:0 3rem;
         box-sizing:border-box;
    }
}
@media only screen and (max-width:767px) {
     .sub-cat-tiles {
         grid-template-columns:repeat(2, 1fr);
    }
}
/*1st level category landing page:ends*/
/*About Us > Our Story:starts*/
.our-story-col {
     padding-bottom:2.5em;
}
.our-story-title h1{
     font-size:2.25rem;
     font-family:'Roboto-Thin';
     text-align:center;
     text-transform:inherit;
     letter-spacing:0;
     margin-top:1.5rem;
     margin-bottom:1.5rem;
}
.our-story-col .owl-stage, .our-story-col .owl-item .item {
     background-color:rgb(246, 246, 246);
}
.our-story-col .owl-item .item > a >img {
     object-fit:cover;
     width:100%;
     height:100%;
     max-height:1px;
}
.story-banner img {
     object-fit:cover;
     width:100%;
     height:100%;
}
.story-banner, .story-banner-txt > div {
     display:grid;
     grid-template-columns:repeat(2, minmax(0, 1fr));
}
.story-banner > p {
     margin:0;
}
.story-banner-txt {
     display:flex;
     flex-direction:column;
}
.story-banner-txt > div {
     padding:3rem;
     box-sizing:border-box;
}
@media only screen and (min-width:1024px) {
     .story-banner-txt > div {
         margin:3rem;
    }
}
/*@media only screen and (max-width:1365px) {
     .story-banner-txt > div {
         padding:3rem 2rem;
         margin:2rem;
    }
}
@media only screen and (max-width:1199px) {
     .story-banner-txt > div {
         padding:2rem;
         margin:1rem 2rem;
    }
}
*/
.story-banner-txt > div > h3 {
     color:#951B4A;
     font-size:2.25rem;
     font-family:'Roboto-Regular';
     text-transform:inherit;
     letter-spacing:0;
     line-height:1.5;
}
.story-txt {
     display:flex;
     flex-direction:column;
}
.story-txt p {
     letter-spacing:.05em;
     line-height:1.375;
     font-size:1rem;
     margin-top:0;
     margin-bottom:1rem;
     color:#000;
}
.story-txt .para1 {
     flex:1 1 0%;
     margin-bottom:2rem;
}
.story-txt .para2 {
     flex:1 1 0%;
}
.story-txt .para2 p {
     font-size:0.8rem !important;
}
.story-txt .para2 br, .story-banner > p > br {
     display:none;
}
/*.our-story-col .owl-item .item > a {
     position:relative;
}
.our-story-col .story-banner {
     position:absolute;
     top:0;
     left:0;
}
*/
.our-story-col .xm-owl-carousel .owl-pagination.owl-dots > button {
     font-size:2.25rem;
     margin:0 0.2em;
}
.our-story-col .xm-owl-carousel .owl-pagination.owl-dots {
     padding-right:5rem;
}
.our-story-col .pagination-pos-over-bottom-right .owl-pagination {
     right:0;
     text-align:right !important;
     background-color:rgb(246, 246, 246);
     padding:1rem 5rem;
     box-sizing:border-box;
     position:relative;
}
@media only screen and (max-width:1023px) {
     .story-banner, .story-banner-txt > div {
         grid-template-columns:repeat(1, minmax(0, 1fr));
    }
}
@media only screen and (max-width:767px) {
     .story-banner-txt > div {
         padding:1.5rem;
    }
}
/*Designer/Builders Hub*/
.hub-section-bnr {
     margin-top:6rem !important;
}
.hub-section-bnr .owl-item .item {
     width:100%;
     height:100%;
     max-height:500px;
     min-height:500px;
     z-index:1;
     position:relative;
}
.hub-section-bnr img {
     object-fit:cover;
     width:100%;
     max-height:500px;
     min-height:500px;
}
.hub-section-bnr .banner-content > div.banner-txt {
     top:50%;
     transform:translateY(-50%);
}
.hub-form {
     margin:0;
     width:100%;
}
.hub-form .hub-form-desc p{
     margin-top:0;
     font-size:1rem;
     margin-bottom:1rem;
     font-family:'Roboto-Light';
     line-height:1.5;
}
.hub-form-desc, .pipedriveWebForms {
     text-align:center;
     margin:4rem auto;
     max-width:42rem;
}
.hub-grid {
     display:grid;
     margin-top:2rem;
     margin-bottom:2rem;
     gap:2rem;
     box-sizing:border-box;
}
.hub-flyer {
     flex-direction:column;
     display:flex;
     justify-content:space-between;
     position:relative;
}
.flyer-content {
     padding-top:1rem;
     padding-bottom:0.5rem;
     position:relative;
}
.flyer-content img {
     object-fit:cover;
     width:12rem;
     margin-bottom:.5rem;
     height:16rem;
}
.flyer-content span:nth-child(2) {
     letter-spacing:.05em;
     text-transform:uppercase;
     font-size:1rem;
     height:1.5rem;
     font-family:'Roboto-Medium';
     display:block;
}
.flyer-content span:nth-child(3) {
     font-size:.875rem;
     padding-top:1.5rem;
     padding-bottom:0;
     display:block;
}
.flyer-links {
     flex-direction:column;
     display:flex;
     position:relative;
}
.flyer-links a {
     color:#951B4A;
     font-size:0.8rem !important;
     margin-top:0rem;
}
.flyer-links a:hover {
     text-decoration:underline;
}
.sample-grid {
     display:grid;
     position:relative;
     margin-top:2.5rem;
     margin-bottom:1rem;
     gap:1.5rem;
     box-sizing:border-box;
}
.single-sample {
     position:relative;
}
.single-sample img {
     padding-bottom:0.75rem;
}
.single-sample span {
     display:block;
     margin:auto;
     font-size:.875rem;
     color:#000;
     font-family:'Roboto-Medium';
}
@media only screen and (min-width:640px) {
     .hub-grid {
         grid-template-columns:repeat(2, minmax(0, 1fr));
    }
     .sample-grid {
         grid-template-columns:repeat(3, minmax(0, 1fr));
    }
}
@media only screen and (min-width:768px) {
     .hub-grid {
         grid-template-columns:repeat(3, minmax(0, 1fr));
         padding-left:3rem;
         padding-right:3rem;
    }
     .single-sample img {
         width:100%;
    }
}
@media only screen and (min-width:1024px) {
     .hub-grid {
         grid-template-columns:repeat(3, minmax(0, 1fr));
         padding-left:6rem;
         padding-right:6rem;
    }
}
@media only screen and (min-width:1200px) {
     .hub-grid {
         grid-template-columns:repeat(4, minmax(0, 1fr));
         padding-left:6rem;
         padding-right:6rem;
    }
}
@media only screen and (min-width:1366px) {
     .hub-grid {
         grid-template-columns:repeat(5, minmax(0, 1fr));
         padding-left:6rem;
         padding-right:6rem;
    }
}
@media only screen and (max-width:639px) {
     .hub-grid {
         grid-template-columns:repeat(1, minmax(0, 1fr));
    }
     .sample-grid {
         grid-template-columns:repeat(2, minmax(0, 1fr));
    }
}
@media only screen and (max-width:767px) {
     .hub-form .grid-column-wrapper {
         height:fit-content;
    }
     .hub-form-desc, .pipedriveWebForms {
         margin:2rem auto;
    }
     .hub-grid {
         padding-left:1.5rem;
         padding-right:1.5rem;
    }
     .pipedriveWebForms iframe {
         width:90% !important;
    }
     .sample-grid {
         gap:.75rem;
    }
}
/*Catalogue*/
.catalogue-section h1 {
     font-size:3rem;
     text-align:center;
     letter-spacing:0;
     text-transform:inherit;
     margin-top:1.5rem;
     margin-bottom:1.5rem;
     line-height:1.25;
     font-family:'Roboto-Thin', sans-serif;
}
.catalogue-section div {
     padding-top:1.5rem;
     padding-bottom:1.5rem;
     max-width:42rem;
     text-align:center;
     margin:auto;
}
.catalogue-section p {
     margin-top:0;
     margin-bottom:1rem;
     font-size:1rem;
     font-family:'Roboto-Light', sans-serif;
     color:#000;
}
@media (max-width:959px) {
     .catalogue-section div {
         max-width:30rem;
    }
}
@media (max-width:767px) {
     .catalogue-section h1 {
         font-size:2.25rem;
    }
     .catalogue-section div {
         max-width:22rem;
    }
}
/*Retailers*/
.retailer-section {
     display:flex;
     flex-direction:column;
     align-items:center;
     padding-top:2.5rem;
     padding-bottom:2.5rem;
}
.retailer-section-inner {
     padding-left:6rem;
     padding-right:6rem;
     box-sizing:border-box;
     width:100%;
}
.retailer-content {
     padding-top:3rem;
     display:flex;
     flex-direction:column;
     align-items:center;
}
.retailer-top {
     margin-left:auto;
     margin-right:auto;
     max-width:42rem;
     padding-bottom:3rem;
     position:relative;
     text-align:center;
}
.retailer-top p {
     margin-top:0;
     margin-bottom:1rem;
     color:#000;
     font-size:1rem;
}
.retailer-top p a {
     text-decoration:none;
     color:#951B4A;
     font-size:1rem;
}
.retailer-heading {
     padding-top:.5rem;
     padding-bottom:.5rem;
     padding-left:.75rem;
     padding-right:.75rem;
     text-align:center;
     color:#fff;
     text-transform:uppercase !important;
     font-family:'Roboto-Regular', sans-serif;
     letter-spacing:.05em;
     font-size:1.125rem;
     background-color:#951B4A;
     box-sizing:border-box;
     width:100%;
}
.showroom-grid {
     gap:2.5rem;
     display:grid;
     width:100%;
     padding:1.5rem;
     text-align:center;
     grid-template-columns:repeat(3, minmax(0, 1fr));
     box-sizing:border-box;
}
.indiv-showroom {
     padding-top:.75rem;
     padding-bottom:.75rem;
}
.indiv-showroom h3 {
     text-transform:uppercase;
     color:#000;
     text-align:center;
     font-size:.875rem;
     font-family:'Roboto-Bold', sans-serif;
}
.indiv-showroom p, .indiv-showroom a {
     margin:0;
     text-align:center;
     color:#000;
     font-size:.875rem;
}
.indiv-showroom a {
     color:#951B4A;
}
.retailers-btm-img img {
     filter:brightness(1);
}
@media only screen and (max-width:1023px) {
     .retailer-section-inner{
         padding-left:1.5rem;
         padding-right:1.5rem;
    }
}
@media only screen and (max-width:767px) {
     .retailer-heading {
         font-size:.875rem;
    }
     .showroom-grid{
         grid-template-columns:repeat(1, minmax(0, 1fr));
    }
}
@media only screen and (min-width:1280px) {
     .showroom-grid {
         gap:1.5rem;
         max-width:48rem;
         margin-left:auto;
         margin-right:auto;
    }
}

/*Finishes*/
.finishes-page, .finishes-page .grid-group-1 .grid-container-wrapper, .finishes-page .grid-group-1 .grid-container-wrapper .grid-column-wrapper, .finishes-page .grid-group-2 .grid-container-wrapper, .finishes-page .grid-group-2 .grid-container-wrapper .grid-column-wrapper {
     width:100%;
     margin:0 !important;
     max-width:100% !important;
}
.finishes-section {
     padding-top:3rem;
     padding-bottom:3rem;
     width:100%;
}
.finishes-subsection1 {
     display:flex;
     flex-direction:row;
}
.finishes-subsection1.stocked-finishes-section {
     flex-direction:row-reverse;
}
.finishes-subsection1 img {
     width:58.333333%;
     padding-left:0;
     padding-right:0;
     object-fit:cover;
     flex-shrink:0;
}
.finishes-subsection1 .finishes-txt {
     align-items:center;
     flex-direction:column;
     display:flex;
     justify-content:center;
     padding-top:3rem;
     padding-bottom:3rem;
     text-align:center;
     padding-left:6rem;
     padding-right:6rem;
}
.finishes-subsection1 .finishes-txt h2 {
     text-align:center;
     line-height:1.25;
     font-size:2.25rem;
     font-family:'Roboto-Thin';
     color:#000;
}
.finishes-subsection1 .finishes-txt > div {
     margin-top:1.5rem;
}
.finishes-subsection1 .finishes-txt > div > p, .living-finishes div p {
     margin-bottom:1rem;
     line-height:1.625;
     font-size:.875rem;
     color:#000;
     margin-top:0;
}
.finishes-subsection1 .finishes-txt > div > p a {
     font-size:.875rem;
     color:#000;
}
.finishes-subsection2 {
     padding-left:6rem;
     padding-right:6rem;
     padding-top:3rem;
     padding-bottom:3rem;
}
.living-finishes {
     align-items:center;
     flex-direction:column;
     display:flex;
     justify-content:center;
}
.living-finishes h3 {
     font-family:'Roboto-Thin';
     line-height:1.25;
     color:#000;
     font-size:1.875rem;
     text-transform:capitalize;
}
.living-finishes .finishes-subcontent {
     max-width:42rem;
     margin-top:1.5rem;
     text-align:center;
}
.finishes-thumbnails {
     position:relative;
     margin-left:auto;
     margin-right:auto;
     max-width:64rem;
     touch-action:auto;
     display:block;
}
.finishes-thumbnails .swatch-wrapper{
     position:relative;
     overflow:hidden;
     height:100%;
     max-width:100%;
     outline:none;
     direction:ltr;
}
.finishes-thumbnails .swatch-wrapper ul{
     list-style:none;
     display:flex;
     margin:0;
     padding:0;
     overflow:hidden;
}
.finishes-thumbnails .swatch-wrapper ul div{
     align-items:center;
     display:flex;
     justify-content:center;
     height:8rem;
     width:8rem;
     cursor:pointer;
     position:relative;
}
.finishes-thumbnails .swatch-wrapper ul div img {
     vertical-align:bottom;
     width:6rem;
     height:6rem;
     position:absolute;
     flex-shrink:0;
}
.finishes-thumbnails .swatch-wrapper ul div span {
     opacity:0;
     transition:opacity 200ms;
     width:100%;
     text-transform:uppercase;
     color:#fff;
     font-family:'Roboto-Regular', sans-serif;
     position:absolute;
     height:100%;
     font-size:1.25rem;
     align-items:center;
     display:flex;
     justify-content:center;
}
.finishes-thumbnails .swatch-wrapper ul div:hover span {
     opacity:1;
}
.standard-swatch-desc {
     margin-left:auto;
     margin-right:auto;
     padding-left:1.5rem;
     padding-right:1.5rem;
     overflow:hidden;
     text-align:center;
     max-width:20rem;
     display:none;
}
.standard-swatch-desc h4 {
     text-transform:uppercase;
     pointer-events:none;
     margin-top:1rem;
     margin-bottom:.5rem;
     font-size:0.8rem;
     color:#000;
     font-family:'Roboto-Regular';
}
.standard-swatch-desc p, .standard-swatch-desc a {
     margin-bottom:.5rem;
     margin-top:0;
     font-size:0.8rem;
     color:#000;
}
.standard-swatch-desc a, .standard-swatch-desc a:hover {
     color:#951B4A;
}
.standard-swatch-desc a:hover {
     text-decoration:underline;
}
.swatch-desc-m {
     display:none;
}
@media only screen and (max-width:1279px) {
     .finishes-subsection1 .finishes-txt {
         padding-left:3rem;
         padding-right:3rem;
    }
}
@media only screen and (max-width:1023px) {
     .finishes-subsection1 {
         flex-direction:column-reverse;
    }
     .finishes-subsection1 img {
         width:100%;
         padding-left:1.5rem;
         padding-right:1.5rem;
    }
     .finishes-subsection2 {
         padding-left:3rem;
         padding-right:3rem;
    }
}
@media only screen and (max-width:959px) {
     .finishes-subsection1 img {
         width:100%;
         padding-left:0;
         padding-right:0;
    }
     .finishes-thumbnails .swatch-wrapper ul {
         flex-wrap:wrap;
    }
     .finishes-subsection1.stocked-finishes-section {
         flex-direction:column-reverse;
    }
}
@media only screen and (max-width:767px) {
     .finishes-section, .finishes-subsection2 {
         padding-top:1.5rem;
         padding-bottom:1.5rem;
    }
     .finishes-subsection2 {
         padding-left:0;
         padding-right:0;
    }
     .living-finishes h3 {
         font-size:1.5rem;
    }
     .finishes-thumbnails .swatch-wrapper ul div{
         height:6rem;
         width:6rem;
    }
     .finishes-thumbnails .swatch-wrapper ul div img {
         height:5rem;
         width:5rem;
    }
     .swatch-desc-m {
         position:relative;
         padding:1.5rem 0;
         display:flex;
         flex-direction:column;
         justify-content:center;
    }
     .swatch-desc-m .standard-swatch-desc {
         display:block;
         margin-left:1%;
         text-align:left;
         padding-left:0;
    }
     .finishes-thumbnails {
         display:none;
    }
     .swatch-desc-m .standard-swatch-desc > span{
         align-items:center;
         display:flex;
         justify-content:center;
         height:6rem;
         width:6rem;
         position:relative;
    }
     .swatch-desc-m .standard-swatch-desc > span img {
         vertical-align:middle;
         width:5rem;
         height:5rem;
         position:absolute;
         flex-shrink:0;
    }
}
@media only screen and (max-width:639px) {
     .finishes-subsection1 .finishes-txt {
         padding-left:1.5rem;
         padding-right:1.5rem;
    }
}
/*Finishes:end*/
/*Contact Us*/
.contact-us-section {
     display:grid;
     grid-template-columns:repeat(2, minmax(0, 1fr));
     width:100%;
     overflow:hidden;
}
.contact-us-section img {
     object-fit:cover;
     margin-bottom:3rem;
     width:100%;
}
.contact-us-section .contact-details {
     position:relative;
     padding-top:3rem;
     padding-bottom:3rem;
     padding-left:6rem;
     padding-right:6rem;
     box-sizing:border-box;
}
.contact-us-section .contact-details h1 {
     font-size:3rem;
     letter-spacing:0;
     text-transform:inherit;
     padding-bottom:.75rem;
     font-family:'Roboto-Thin', sans-serif;
     line-height:1.5;
}
.contact-us-section .au-section {
     position:relative;
     margin-top:2rem;
     margin-bottom:2rem;
}
.contact-us-section .au-section h2 {
     text-transform:uppercase;
     padding-bottom:.5rem;
     font-family:'Roboto-Bold';
     font-size:1rem;
     line-height:1.5;
}
.contact-us-section .au-section .au-details, .contact-us-section .nz-details {
     display:flex;
     flex-direction:column;
     margin-bottom:3rem;
}
.contact-us-section .au-section .au-details a, .contact-us-section .nz-details a {
     display:flex;
     align-items:center;
     padding-top:.25rem;
     color:#000;
     font-size:1rem;
     line-height:1.5;
}
.contact-us-section .au-section .au-details h3 {
     padding-top:.5rem;
     padding-bottom:.5rem;
     font-family:'Roboto-Bold';
     font-size:1rem;
     line-height:1.5;
     text-transform:inherit;
}
.contact-us-section .nz-hd {
     margin-bottom:1rem;
     margin-top:0;
     color:#000;
     font-family:'Roboto-Bold';
     font-size:1rem;
     line-height:1.5;
}
.contact-us-section .nz-map {
     max-width:20rem;
     color:#000;
     margin-bottom:1rem;
     margin-top:0;
}
.contact-us-section .nz-map a {
     color:#000;
     font-size:1rem;
     line-height:1.5;
}
.contact-us-section .au-details .fa {
     color:#951B4A;
     width:16px;
     padding-right:0.5em;
     font-size:1.15em;
     min-width:15px;
}
.contact-us-section svg {
     width:1.15em !important;
     height:1.15em !important;
     padding-right:0.5em;
}
.abn-details {
     color:#000;
     font-size:1rem;
     line-height:1.5;
     margin-top:1rem;
     margin-bottom:1rem;
}
.contact-us-section .send-msg-hd {
     line-height:1.25;
     font-size:2.25rem;
     font-family:'Roboto-Thin';
     text-transform:inherit;
}
.contact-us-section .pipedriveWebForms {
     margin:3rem 0;
}
@media only screen and (min-width:768px) {
     .contact-us-section img {
         width:50%;
         right:0;
         top:0;
         position:fixed;
         padding-left:8rem;
         height:100vh;
    }
}
@media only screen and (max-width:1023px) {
     .contact-us-section .contact-details {
         padding-left:3rem;
         padding-right:3rem;
    }
}
@media only screen and (max-width:767px) {
     .contact-us-section {
         grid-template-columns:repeat(1, minmax(0, 1fr));
    }
     .contact-us-section .contact-details {
         padding-left:1.5rem;
         padding-right:1.5rem;
    }
     .contact-us-section .contact-details h1 {
         font-size:2.25rem;
    }
     .contact-us-section img {
         width:100%;
    }
}
/*Contact Us:ends*/
/*Careers*/
.careers-page, .careers-page .grid-group-1 .grid-container-wrapper, .careers-page .grid-group-1 .grid-container-wrapper .grid-column-wrapper, .careers-page .grid-group-2 .grid-container-wrapper, .careers-page .grid-group-2 .grid-container-wrapper .grid-column-wrapper, .careers-page .grid-group-3 .grid-container-wrapper, .careers-page .grid-group-3 .grid-container-wrapper .grid-column-wrapper, .careers-page .grid-group-4 .grid-container-wrapper, .careers-page .grid-group-4 .grid-container-wrapper .grid-column-wrapper:not(.pathway-carousel), .careers-page .grid-group-5 .grid-container-wrapper, .careers-page .grid-group-5 .grid-container-wrapper .grid-column-wrapper, .careers-page .grid-group-6 .grid-container-wrapper, .careers-page .grid-group-6 .grid-container-wrapper .grid-column-wrapper {
     width:100%;
     margin:0 !important;
     max-width:100% !important;
}
.careers-subsection1 {
     padding-top:2.5rem;
     padding-bottom:2.5rem;
}
.careers-page .jc {
     justify-content:center;
}
.careers-page .ac{
     align-items:center;
}
.careers-page .flex-col {
     display:flex;
     flex-direction:column;
}
.careers-page .mauto {
     margin-left:auto;
     margin-right:auto;
}
.careers-page h2{
     text-align:center;
     line-height:1.25;
     font-size:2.25rem;
     font-family:'Roboto-Thin';
     color:#000;
}
.mission-values {
     display:grid;
     margin-top:2rem;
     margin-bottom:2rem;
     grid-template-columns:repeat(1, minmax(0, 1fr));
     padding-left:1.5rem;
     padding-right:1.5rem;
     width:100%;
     box-sizing:border-box;
}
@media only screen and (min-width:640px) {
     .mission-values {
         grid-template-columns:repeat(2, minmax(0, 1fr));
    }
}
@media only screen and (min-width:1024px) {
     .mission-values {
         padding-left:16rem;
         padding-right:16rem;
         gap:6rem;
    }
}
.mv-sub {
     position:relative;
     overflow:hidden;
     text-align:center;
     width:100%;
}
.mv-sub h2 {
     color:#951B4A;
     font-size:1.875rem;
     font-weight:300;
     font-family:'Roboto-Light';
}
.mv-sub div {
     text-align:center;
     padding-left:1.5rem;
     padding-right:1.5rem;
     max-width:42rem;
     margin-top:1.5rem;
     box-sizing:border-box;
}
.mv-sub p {
     margin-bottom:1rem;
     font-size:1rem;
     font-weight:300;
     font-family:'Roboto-Light';
}
.careers-subsection1 h3 {
     font-size:1.875rem;
     margin-top:1.5rem;
     margin-bottom:1.5rem;
     font-weight:300;
     font-family:'Roboto-Light';
     color:#951B4A;
}
.contact-card-grid {
     grid-template-columns:repeat(5, minmax(0, 1fr));
     gap:1rem;
     width:100%;
     padding:3rem .5rem;
     box-sizing:border-box;
     display:grid;
}
.contact-card-grid .py-6 {
     padding-top:1.5rem;
     padding-bottom:1.5rem;
     box-sizing:border-box;
}
.contact-card-grid .py-12 {
     padding-top:3rem;
     padding-bottom:3rem;
     box-sizing:border-box;
}
.contact-card-grid .grid {
     display:grid;
}
.contact-card-grid .w-full {
     width:100%;
}
.contact-card-grid .relative {
     position:relative;
}
.contact-card-grid .overflow-hidden {
     overflow:hidden;
}
.contact-card-grid .mx-auto {
     margin-left:auto;
     margin-right:auto;
}
.contact-card-grid .justify-between {
     justify-content:space-between;
}
.contact-card-grid .flex-col {
     flex-direction:column;
}
.contact-card-grid .flex {
     display:flex;
}
.contact-card-grid .profile {
     width:100% !important;
     padding-top:100% !important;
}
.contact-card-grid .mb-2 {
     margin-bottom:.5rem;
}
.contact-card-grid .profile input {
     cursor:pointer !important;
     opacity:0;
     position:absolute;
     top:0;
     right:0;
     width:35px;
     height:35px;
}
.contact-card-grid .z-20 {
     z-index:2;
}
.contact-card-grid .bg-light {
     background-color:rgb(255, 255, 255);
}
.contact-card-grid .z-0 {
     z-index:0;
}
.contact-card-grid .top-0 {
     top:0;
}
.contact-card-grid .absolute {
     position:absolute;
}
.contact-card-grid .p-4 {
     padding:1rem;
     box-sizing:border-box;
}
.contact-card-grid .h-full {
     height:100%;
}
.contact-card-grid .z-10 {
     z-index:10;
}
.contact-card-grid .cursor-pointer {
     cursor:pointer;
}
.contact-card-grid .object-cover {
     object-fit:cover;
}
.contact-card-grid .flex-shrink-0 {
     flex-shrink:0;
}
.contact-card-grid .profile-plus {
     color:rgba(255, 255, 255, 1);
     right:0;
     top:0;
     position:absolute;
     font-size:1.5rem;
     cursor:pointer;
     background-color:rgba(0, 0, 0, 0.75);
}
.contact-card-grid .tracking-wider {
     letter-spacing:.05em;
}
.contact-card-grid .uppercase {
     text-transform:uppercase;
}
.contact-card-grid .text-sm {
     font-size:.875rem;
}
.contact-card-grid .font-medium {
     font-weight:500;
     font-family:'Roboto-Medium';
}
.contact-card-grid .text-xs {
     font-size:0.8rem !important;
}
.contact-card-grid .font-normal {
     font-weight:400;
     font-family:'Roboto-Regular';
     line-height:1.5;
}
.contact-card-grid .px-2 {
     padding-left:.5rem;
     padding-right:.5rem;
     box-sizing:border-box;
}
.contact-card-grid .overflow-auto {
     overflow:auto;
}
.contact-card-grid .h-48 {
     height:12rem;
}
.contact-card-grid .p-color a{
     color:#951B4A !important;
     font-size:0.8rem !important;
     font-family:'Roboto-Medium';
}
 .contact-card-grid .p-color a i.fa {
     margin-right:5px;
     font-size:0.8rem !important;
     display:none;
}
.contact-card-grid .mt-2 {
     margin-top:.5rem;
}
.contact-card-grid .mt-6 {
     margin-top:1.5rem;
}
.contact-card-grid .m-1 {
     margin:.25rem;
}
.contact-card-grid .items-center {
     align-items:center;
}
.contact-card-grid .truncate {
     overflow:hidden;
     text-overflow:ellipsis;
     white-space:nowrap;
}
.contact-card-grid .text-white {
     color:rgba(255, 255, 255, 1);
}
.contact-card-grid .right-0 {
     right:0;
}
.contact-card-grid .text-2xl {
     font-size:1.5rem;
}
.contact-card-grid .bg-black {
     background-color:rgba(0, 0, 0, 0.75);
}
.contact-card-grid .profile input:checked~.profile-img {
     display:none !important;
}
 .contact-card-grid .profile-details div.cursor-pointer, .contact-card-grid .profile-img .profile-plus {
     width:35px;
     height:35px;
     display:flex;
     justify-content:center;
     align-items:center;
}
.contact-card-grid .profile-details h2 {
     text-transform:uppercase !important;
     font-size:1.25rem;
     font-weight:700;
     color:#000;
     font-family:'Roboto-Bold';
}
.contact-card-grid .profile-img .relative > h2{
     color:#fff;
     text-transform:uppercase !important;
     text-align:center;
     max-width:42rem;
     padding-bottom:1.5rem;
     padding-top:1.5rem;
     margin-top:1.5rem;
     margin-left:auto;
     margin-right:auto;
     font-size:1.25rem;
     font-weight:700;
     font-family:'Roboto-Bold';
     box-sizing:border-box;
}
.contact-card-grid .leading-normal p{
     line-height:1.5;
}
.contact-card-grid .font-light p{
     font-weight:300;
     margin-top:0;
     font-size:0.8rem;
     font-family:'Roboto-Light';
}
.contact-card-grid .font-light {
     text-align:center;
}
 .contact-card-grid .profile-details > div.text-xs p {
     font-size:0.8rem !important;
     letter-spacing:.05em;
     margin-bottom:1rem;
     margin-top:0;
}
 .profile-details .has-list-items ul {
     margin-left:0;
}
 .profile-details .has-list-items ol li, .profile-details .has-list-items ul li {
     list-style-type:none !important;
     padding-bottom:1rem;
     display:-webkit-box;
     display:-ms-flexbox;
     display:flex;
     -webkit-box-align:start;
     -ms-flex-align:start;
     align-items:center;
     line-height:1;
}
 .profile-details .has-list-items li:before {
     content:var(--arrow);
     display:inherit;
     font-size:0px;
     vertical-align:middle !important;
     width:20px;
     height:20px;
}
 @media only screen and (max-width:1023px) {
     .contact-card-grid {
         grid-template-columns:repeat(1, minmax(0, 1fr));
         padding:3rem 1.5rem;
    }
}
.who-we-are-txt {
     padding-left:1.5rem;
     padding-right:1.5rem;
     text-align:center;
     max-width:42rem;
}
.who-we-are-txt p {
     font-size:1rem;
     font-weight:300;
     margin-top:0;
     margin-bottom:1rem;
     font-family:'Roboto-Light';
}
#who-we-are h2 {
     margin-top:1.5rem;
     margin-bottom:1.5rem;
}
#who-we-are h3 {
     padding-top:3.5rem;
     color:#000;
     text-transform:inherit;
     line-height:1.25;
     font-size:1.875rem;
     text-align:center;
     font-weight:100;
     font-family:'Roboto-Thin';
}
@media only screen and (min-width:768px) {
     .contact-card-grid.who-we-are-grid, .contact-card-grid.benefits-grid {
         grid-template-columns:repeat(3, minmax(0, 1fr));
         box-sizing:border-box;
         max-width:72rem;
    }
}
@media only screen and (min-width:1024px) {
     .contact-card-grid.who-we-are-grid, .contact-card-grid.benefits-grid {
         grid-template-columns:repeat(3, minmax(0, 1fr));
         padding-left:3rem;
         padding-right:3rem;
    }
}
.contact-card-grid.who-we-are-grid .block.mt-5 {
     display:block;
     text-align:center;
     margin-top:1.25rem;
}
.contact-card-grid.who-we-are-grid .block.mt-5 p{
     font-size:0.8rem;
     padding-left:.5rem;
     padding-right:.5rem;
     margin-bottom:1rem;
     color:#951b4a;
     margin-top:0;
     margin-bottom:1rem;
}
#who-we-are .contact-card-grid.who-we-are-grid h2, #team-benefits .contact-card-grid.benefits-grid h2 {
     margin-bottom:0;
     margin-top:0.7rem;
}
#who-we-are .contact-card-grid.who-we-are-grid .profile.relative.overflow-hidden, #team-benefits .contact-card-grid.benefits-grid .profile.relative.overflow-hidden {
     overflow:visible;
}
.contact-card-grid.benefits-grid .font-normal.tracking-wider.text-base {
     text-align:center;
}
.contact-card-grid.benefits-grid .font-normal.tracking-wider.text-base p {
     letter-spacing:.05em;
     font-size:1rem;
     font-weight:400;
     margin:0 auto 1rem;
     font-family:'Roboto-Regular';
}
#team-benefits > h2, #vacancies > h2, #career-pathways > h2 {
     margin-top:1.5rem;
     margin-bottom:1.5rem;
     padding-top:5rem;
}
.current-vacancies {
     padding-left:1.5rem;
     padding-right:1.5rem;
     box-sizing:border-box;
}
@media only screen and (min-width:1024px) {
     .current-vacancies {
         padding-left:6rem;
         padding-right:6rem;
    }
}
#vacancies h3 {
     font-weight:700;
     font-size:0.875rem;
     text-transform:uppercase;
     font-family:'Roboto-Bold';
}
.anchor-links-vacancy {
     width:100%;
}
.anchor-links-vacancy ul {
     list-style:none;
     display:flex;
     flex-direction:row;
     justify-content:center;
     flex-wrap:wrap;
     margin:auto;
     border-bottom:1px solid #e2e8f0;
     padding-top:2.5rem;
     padding-bottom:0;
}
.anchor-links-vacancy ul li {
     font-size:1rem;
     line-height:1.5;
     padding:0 0 .5rem;
     cursor:pointer;
     flex:1 1 auto;
     text-align:center;
     margin-right:0.5rem;
}
@media only screen and (min-width:768px) {
     .anchor-links-vacancy ul li.state-selected {
         border-bottom:3px solid #951B4A;
    }
}
@media only screen and (max-width:767px) {
     .anchor-links-vacancy ul li {
         padding:1rem 1.5rem .5rem;
    }
     .anchor-links-vacancy ul li.state-selected {
         color:#951B4A;
    }
     .anchor-links-vacancy ul {
         flex-direction:column;
    }
}
.vacancy-subsection .page-content{
     padding:1.5rem;
}
.vacancy-subsection .page-content p {
     font-family:'Roboto-Light';
     font-size:1rem;
}
.careers-page .career-pathways .grid-column-wrapper.pathway-carousel {
     max-width:72rem;
     margin:0 auto;
}
.pathway-carousel .owl-carousel .owl-item .fade-on-slideshow-hover > img {
     width:50% !important;
     margin:auto;
}
.pathway-carousel .quote-text p {
     margin-top:0;
     margin-bottom:1rem;
     font-size:1rem;
     text-align:center;
     font-family:'Roboto-Light';
     color:#000;
}
.pathway-carousel .quote-text > p {
     display:none;
}
.pathway-carousel .quote-text .staff-quote .staff-nm {
     padding-top:1.25rem;
     color:#951B4A;
}
.pathway-carousel .quote-text .staff-quote .staff-dept {
     padding-top:.5rem;
     color:#951B4A;
     font-size:1.125rem;
     font-weight:700;
     font-family:'Roboto-Bold';
     text-transform:uppercase;
}
.pathway-carousel .quote-text .staff-quote img {
     width:15px;
     margin:auto;
     margin-top:1.5rem;
}
.pathway-carousel .quote-text .staff-quote .quote-txt {
     padding-left:1.5rem;
     padding-right:1.5rem;
     max-width:42rem;
     margin-top:1.5rem;
}
.pathway-carousel .quote-text .staff-quote .quote-txt > br, .pathway-carousel .quote-text .staff-quote > br {
     display:none;
}
/*Pagination dots*/
.pathway-carousel .owl-pagination {
     margin-top:2rem;
}
 .pathway-carousel .owl-theme .owl-dots .owl-dot span {
     width:12px !important;
     height:12px !important;
     background:transparent !important;
     border:1px solid #951B4A !important;
}
.pathway-carousel .owl-theme .owl-dots .owl-dot.active span, .pathway-carousel .owl-theme .owl-dots .owl-dot:hover span {
     background:#951B4A !important;
}
.career-btm-img img {
     padding-top:5rem;
     object-fit:cover;
     width:100%;
}
/*technical - how to videos*/
.video-thumb {
     cursor:pointer;
}
.video-thumb > figure {
     position:relative;
}
@media only screen and (min-width:768px) {
     .video-thumb > figure figcaption {
         position:absolute;
         top:50%;
         left:0;
         width:100%;
         transform:translateY(-50%);
         text-align:center;
         color:#fff;
         text-transform:uppercase;
    }
     .video-thumb > figure figcaption span{
         overflow-wrap:break-word;
         letter-spacing:.05em;
         padding-left:1rem;
         padding-right:1rem;
    }
     .video-thumb > figure h3 {
         display:none;
    }
}
@media only screen and (max-width:767px) {
     .video-thumb > figure figcaption {
         display:none;
    }
     .video-thumb > figure h3 {
         position:absolute;
         bottom:0;
         left:50%;
         transform:translateX(-50%);
         padding:.5rem;
         font-size:1.125rem;
         font-family:'Roboto-Medium';
         text-align:center;
         color:#fff;
         text-transform:uppercase;
         letter-spacing:.05em;
         overflow:hidden;
         text-overflow:ellipsis;
         white-space:nowrap;
         z-index:20;
         display:block;
    }
}
 .video-grid {
     display:grid;
     grid-template-columns:repeat(1, minmax(0, 1fr));
     gap:1.25rem;
     margin-top:4rem;
     width:100%;
}
 @media only screen and (min-width:768px) {
     .video-grid {
         grid-template-columns:repeat(2, minmax(0, 1fr));
    }
}
 @media only screen and (min-width:1280px) {
     .video-grid {
         grid-template-columns:repeat(3, minmax(0, 1fr));
    }
}
#videoModal {
     position:fixed;
     top:0;
     left:0;
     width:100%;
     height:100%;
     z-index:9999;
     display:none;
     background-color:rgba(0, 0, 0, 0.9);
     justify-content:center;
     align-items:center;
}
.videoOuterDiv {
     display:flex;
     justify-content:center;
     align-items:center;
     height:100%;
}
#videoModal iframe {
     width:80%;
     height:80%;
     border-radius:10px;
}
#closeModal {
     position:absolute;
     top:10px;
     right:10px;
     background:none;
     border:none;
     color:white;
     font-size:2rem;
     cursor:pointer;
     z-index:10000;
}
/*Look Book content*/
.look-book-carousel-heading {
     text-align:center;
     max-width:65rem;
     margin:0 auto 3rem;
}
.look-book-carousel-heading p {
     font-size:1rem;
     font-family:'Roboto-Light';
     margin-bottom:1rem;
     margin-top:0;
}
.look-book-carousel-products {
     grid-template-columns:repeat(2, minmax(0, 1fr));
     width:100;
     position:relative;
     display:grid;
     box-sizing:border-box;
     gap:1.5rem;
}
.look-book-carousel-products img {
     margin-left:auto;
     margin-right:auto;
     object-fit:cover;
     width:100%;
     height:100%;
}
.look-book-products-grid {
     position:relative;
}
.look-book-products-grid h3 {
     margin-bottom:2rem;
     font-family:'Roboto-Thin';
     font-size:2.25rem;
     margin-top:0.5rem;
}
.look-book-products-grid > p {
     font-size:1rem;
     font-family:'Roboto-Light';
     margin-bottom:1rem;
     margin-top:0;
}
.products-flex {
     gap:1rem;
     grid-template-columns:repeat(1, minmax(0, 1fr));
     display:grid;
     justify-content:flex-start;
     position:relative;
     align-items:flex-start;
}
.look-book-product-data {
     display:flex;
     justify-content:flex-start;
     flex-direction:column;
     align-items:center;
     gap:0.875rem;
     position:relative;
}
.look-book-product-data a img {
     margin-left:auto;
     margin-right:auto;
     width:75%;
}
.look-book-product-desc {
     justify-content:space-between;
     align-items:flex-start;
     flex-direction:column;
     display:flex;
}
.desc-wrapper {
     position:relative;
}
.look-book-product-data .catalogue-buttons a {
     font-size:0.8rem !important;
     color:#951b4a;
}
.prod-attributes span {
     letter-spacing:.05em;
     font-size:0.8rem;
     padding-top:.25rem;
     padding-bottom:.25rem;
}
.prod-attributes span:nth-child(1) {
     font-size:11px !important;
     padding-right:0.2rem;
     color:#951b4a;
     text-transform:uppercase;
}
.desc-wrapper .more-desc {
     padding-top:.5rem;
     position:relative;
}
.desc-wrapper .more-desc p {
     margin-bottom:1rem;
     margin-top:0;
     font-size:.875rem;
}
@media (min-width:1280px) {
     .look-book-product-data a img {
         width:100%;
    }
     .desc-wrapper .more-desc {
         padding-top:.5rem;
         padding-top:1.5rem;
    }
}
@media screen and (min-width:1440px) {
     .look-book-carousel-products {
         grid-template-columns:repeat(5, minmax(0, 1fr));
    }
     .look-book-carousel-products > img {
         grid-column:1/span 2;
    }
     .products-flex {
         grid-template-columns:repeat(3, minmax(0, 1fr));
         gap:0.5rem;
    }
     .look-book-products-grid {
         grid-column:span 3 / span 3;
    }
     .look-book-product-data:not(:first-child) {
         padding-left:1.25rem;
         padding-right:1.25rem;
    }
     .look-book-product-data:not(:last-child) {
         border-right:1px solid #951b4a;
         padding-right:1.25rem;
         flex-direction:column;
    }
     .look-book-product-data a img {
         -webkit-transform:scale(0.9);
         -ms-transform:scale(0.9);
         transform:scale(0.9);
         padding-bottom:1.5rem;
    }
     .look-book-product-data .catalogue-buttons {
         padding-top:1.5rem;
         padding-bottom:1.5rem;
    }
}
